const HEADER_HEIGHT = 70;

function isInViewport(elem) {
  const bounding = elem.getBoundingClientRect();

  return (
    bounding.top >= HEADER_HEIGHT &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
 * Scrolls the given element into the viewport on browsers that support
 * Element.scrollIntoViewIfNeeded. Otherwise, it scrolls the element into the
 * viewport with an assumption that documentElement is its scrollable parent.
 *
 * @param {HTMLElement} element - The element itself to be put in the viewport
 * @param {boolean} centerIfNeeded - If the element needs to be centered.
 * Be careful of partial support for modern browsers
 */
export default function scrollIntoViewIfNeeded(
  element,
  centerIfNeeded = false
) {
  if (isInViewport(element)) return undefined;

  if (Element.prototype.scrollIntoViewIfNeeded) {
    return element.scrollIntoViewIfNeeded(centerIfNeeded);
  }

  element.scrollIntoView();

  // Not to overlap with the sticky header
  document.documentElement.scrollTop -= HEADER_HEIGHT;

  return undefined;
}
