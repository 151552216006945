import { Grid, Typography } from '@mui/material';
import {
  addDays,
  addMonths,
  format as formatDate,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { de } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';

import {
  ArrowButton,
  DayGridItem,
  Header,
  HeadingMonth,
  WeekDaysWrapper,
  Wrapper,
} from './Calendar.style';

/**
 * Calendar view. A part of InputCalendar that can be used separately.
 */
const Calendar = ({
  selected,
  presented,
  disabledFrom,
  disabledTo,
  onChange,
  className,
  isDateDisabled,
}) => {
  const [date, setDate] = useState(selected || presented);

  // return 0 for monday
  const getDay = date => (date.getDay() + 6) % 7;

  const dateIsEqual = (a, b) => a && b && isEqual(startOfDay(a), startOfDay(b));

  const increaseMonth = diff => {
    setDate(prevDate => addMonths(prevDate, diff));
  };

  const isDisabled = date =>
    Boolean(
      (disabledFrom && isAfter(date, startOfDay(disabledFrom))) ||
        (disabledTo && isBefore(date, startOfDay(disabledTo))) ||
        isDateDisabled?.(date)
    );

  const emptyCells = () => {
    const day = getDay(startOfMonth(date));
    return [...new Array(day)];
  };

  const days = () => {
    const count = getDaysInMonth(date);
    const firstDay = startOfMonth(date);
    return [...new Array(count).keys()].map(i => addDays(firstDay, i));
  };

  const renderHeader = () => (
    <Header>
      <ArrowButton
        type="button"
        data-cy="calendar: prev month"
        onClick={() => increaseMonth(-1)}
        direction="left">
        <SvgIcon name="arrow" color="primary" />
      </ArrowButton>
      <HeadingMonth data-cy="calendarDate">
        {formatDate(date, 'MMMM yyyy', { locale: de })}
      </HeadingMonth>
      <ArrowButton
        type="button"
        data-cy="calendar: next month"
        onClick={() => increaseMonth(1)}
        direction="right">
        <SvgIcon name="arrow" color="primary" />
      </ArrowButton>
    </Header>
  );

  const renderDay = date => {
    const disabled = isDisabled(date);
    return (
      <Grid item xs={1} key={date.toISOString()}>
        <DayGridItem
          data-is-disabled={disabled}
          data-cy={`calendar: day ${formatDate(date, 'dd')}`}
          selected={dateIsEqual(date, selected)}
          disabled={disabled}
          onClick={disabled ? null : () => onChange(date)}>
          {formatDate(date, 'd')}
        </DayGridItem>
      </Grid>
    );
  };

  return (
    <Wrapper className={className}>
      {renderHeader()}
      <Grid container columns={7} rowSpacing={0.5}>
        <WeekDaysWrapper container as={Grid} columns={7}>
          {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map(dayName => (
            <Grid item xs={1} key={dayName}>
              <Typography variant="label" align="center" component="div">
                {dayName}
              </Typography>
            </Grid>
          ))}
        </WeekDaysWrapper>
        {emptyCells().map((_, i) => (
          <Grid item xs={1} key={i} />
        ))}
        {days().map(renderDay)}
      </Grid>
    </Wrapper>
  );
};

Calendar.propTypes = {
  /** Selected day. It's also presented month at start. */
  selected: PropTypes.instanceOf(Date),
  /** Presented month at start. Used only if there is no selected date. */
  presented: PropTypes.instanceOf(Date),
  /** Disable range of dates. */
  disabledFrom: PropTypes.instanceOf(Date),
  /** Disable range of dates. */
  disabledTo: PropTypes.instanceOf(Date),
  /** Event listener. Called with Date in parameter. */
  onChange: PropTypes.func,
  /** Css theme */
  className: PropTypes.string,
  /** Function checking if date is disabled. Should return boolean */
  isDateDisabled: PropTypes.func,
};

Calendar.defaultProps = {
  selected: null,
  presented: new Date(),
  disabledFrom: null,
  disabledTo: null,
  onChange: () => {},
  className: '',
  isDateDisabled: null,
};

export default Calendar;
