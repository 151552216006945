import { styled } from '@mui/material/styles';

import theme from '@/styles/theme';

export const Wrapper = styled('div')({
  padding: theme.spacing(1),
  maxWidth: 225,
  margin: '0 auto',
});

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2.5),
}));

export const HeadingMonth = styled('div')(({ theme }) => ({
  ...theme.typography.h4,
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.primary.main,
}));

export const WeekDaysWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const ArrowButton = styled('button', {
  shouldForwardProp: prop => prop !== 'direction',
})(({ theme, direction }) => ({
  border: 'none',
  backgroundColor: theme.palette.primaryLight.main,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  width: 32,
  height: 32,
  padding: 0,
  marginLeft: direction === 'left' ? theme.spacing(0.5) : 0,
  marginRight: direction === 'left' ? 0 : theme.spacing(0.5),

  '& > svg': {
    transform: direction === 'left' ? 'rotate(90deg)' : 'rotate(-90deg)',
  },
}));

export const DayGridItem = styled('div', {
  shouldForwardProp: prop => !['selected', 'disabled'].includes(prop),
})(({ theme, selected, disabled }) => ({
  ...theme.typography.label,
  fontSize: theme.typography.pxToRem(14),
  textAlign: 'center',
  cursor: 'pointer',
  width: 34,
  height: 34,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',

  ...(selected && {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBlack,
    cursor: 'default',
    borderRadius: '50%',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
  }),

  ...(disabled && {
    cursor: 'default',
    color: theme.palette.grayscale[400],
  }),
}));
