import { Snackbar as MuiSnackbar, snackbarClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSnackbar = styled(MuiSnackbar)(({ theme }) => ({
  minWidth: '50%',

  [`&.${snackbarClasses.anchorOriginTopCenter}`]: {
    top: 120,
  },

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(1),
    right: theme.spacing(1),
    transform: 'translateX(0)',
    minWidth: 'initial',
  },
}));
