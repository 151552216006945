import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@/components/ui/ClickAwayListener/ClickAwayListener';
import Input from '@/components/ui/Input/Input';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import scrollIntoViewIfNeeded from '@/helpers/scrollIntoViewIfNeeded';
import { formatDate } from '@/helpers/utils';

import Calendar from './Calendar/Calendar';
import { CalendarWrapper, Label, Wrapper } from './InputCalendar.style';

/**
 * User control for selecting a day.
 */
const InputCalendar = ({
  id,
  name,
  label,
  value,
  style,
  disabled,
  disabledFrom,
  disabledTo,
  presented,
  onChange,
  onBlur,
  className,
  calendarClassName,
  inputRef,
  tabIndex,
  error,
  required,
  helperText,
  isDateDisabled,
}) => {
  const [unfolded, setUnfolded] = useState(false);
  const [innerValue, setInnerValue] = useState(null);
  const innerInputRef = useRef(null);
  const calendarRef = useRef(null);
  const isControlled = typeof value !== 'undefined';

  const getValue = () => (isControlled ? value : innerValue);

  const handleOpenCalendar = () => setUnfolded(true);

  const handleIconClick = () => {
    if (!disabled) {
      innerInputRef.current.focus();
    }
  };

  const handleCalendarChange = date => {
    setInnerValue(date);
    setUnfolded(false);
    onChange(date);
  };

  const getInputIconColor = () => {
    if (error) {
      return 'error';
    }
    if (disabled) {
      return 'disabled';
    }
    return 'primary';
  };

  useEffect(() => {
    if (unfolded) {
      scrollIntoViewIfNeeded(calendarRef.current);
    }
  }, [unfolded]);

  return (
    <Wrapper className={className} style={style}>
      {unfolded ? (
        <ClickAwayListener onClickAway={() => setUnfolded(false)}>
          <CalendarWrapper
            ref={calendarRef}
            className={calendarClassName}
            elevation={2}>
            <Label>
              <SvgIcon name="calendar" color="primary" />
              {label}
            </Label>
            <Calendar
              disabledFrom={disabledFrom}
              disabledTo={disabledTo}
              selected={getValue()}
              onChange={handleCalendarChange}
              presented={presented}
              isDateDisabled={isDateDisabled}
            />
          </CalendarWrapper>
        </ClickAwayListener>
      ) : null}
      <Input
        readOnly
        id={id}
        disabled={disabled}
        inputRef={e => {
          innerInputRef.current = e;

          if (inputRef) {
            if (typeof inputRef === 'function') {
              inputRef(e);
            } else {
              inputRef.current = e;
            }
          }
        }}
        onFocus={handleOpenCalendar}
        onBlur={onBlur}
        label={label}
        value={formatDate(getValue())}
        error={error}
        required={required}
        fullWidth
        name={name}
        helperText={helperText}
        {...(tabIndex !== null && {
          tabIndex: tabIndex,
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SvgIcon
                name="calendar"
                color={getInputIconColor()}
                onClick={handleIconClick}
                sx={{
                  cursor: disabled ? 'initial' : 'pointer',
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Wrapper>
  );
};

InputCalendar.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  /** Displayed name of input. */
  label: PropTypes.string,
  /** Displayed value. Triggers controlled mode. */
  value: PropTypes.instanceOf(Date),
  /** Disable Input */
  disabled: PropTypes.bool,
  /** Disable range of dates. */
  disabledFrom: PropTypes.instanceOf(Date),
  /** Disable range of dates. */
  disabledTo: PropTypes.instanceOf(Date),
  /** Event listener. Called with Date in parameter. */
  onChange: PropTypes.func,
  /** OnBlur event listener. */
  onBlur: PropTypes.func,
  /** First presented month when calendar is revealed. */
  presented: PropTypes.instanceOf(Date),
  /** Css theme. */
  style: PropTypes.object,
  /** Css theme. */
  className: PropTypes.string,
  /** Css theme for calendar wrapper. */
  calendarClassName: PropTypes.string,
  /** Ref to raw html element. */
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  /** Tab index passed to input. */
  tabIndex: PropTypes.number,
  /** Show error Input state */
  error: PropTypes.bool,
  /** Show helper text for Input */
  helperText: PropTypes.string,
  /** Show required styles for Input*/
  required: PropTypes.bool,
  /** Function checking if date is disabled. Should return boolean */
  isDateDisabled: PropTypes.func,
};

InputCalendar.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: undefined,
  style: {},
  disabled: false,
  disabledFrom: null,
  disabledTo: null,
  presented: new Date(),
  onChange: () => {},
  onBlur: () => {},
  className: '',
  calendarClassName: '',
  inputRef: null,
  tabIndex: null,
  error: false,
  helperText: '',
  required: false,
  isDateDisabled: null,
};

export default InputCalendar;
