import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/styled-engine';

import Paper from '@/components/ui/Paper/Paper';

const appear = keyframes`
   from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
`;

export const Wrapper = styled('div')(() => ({
  position: 'relative',
}));

export const CalendarWrapper = styled(Paper)(() => ({
  width: '100%',
  position: 'absolute',
  animationName: appear,
  animationDuration: '0.3s',
  overflow: 'hidden',
  zIndex: 2,
}));

export const Label = styled('div')(({ theme }) => ({
  ...theme.typography.label,
  fontSize: theme.typography.pxToRem(14),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 48,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,

  svg: {
    marginRight: theme.spacing(0.5),
  },
}));
