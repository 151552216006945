import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { StyledSnackbar } from './Snackbar.style';

const Snackbar = ({
  open,
  onClose,
  message,
  severity,
  SnackbarProps,
  AlertProps,
  autoHideDuration = 3000,
}) => {
  const mobileMediaQuery = useMobileMediaQuery();

  return (
    <StyledSnackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{
        vertical: mobileMediaQuery ? 'bottom' : 'top',
        horizontal: 'center',
      }}
      {...SnackbarProps}>
      <Alert
        data-cy="snackbar"
        sx={{ width: '100%' }}
        severity={severity}
        elevation={1}
        {...AlertProps}>
        {message}
      </Alert>
    </StyledSnackbar>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  // Snackbar props documentation https://mui.com/api/snackbar/
  SnackbarProps: PropTypes.object,
  // Alert props documentation https://mui.com/api/alert/
  AlertProps: PropTypes.object,
};

Snackbar.defaultProps = {
  severity: 'error',
  SnackbarProps: {},
  AlertProps: {},
};

export default Snackbar;
